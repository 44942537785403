import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { MetaData } from '../components/common/meta'
import { NetlifyContactForm } from '../components/shortcodes'
import { MainLayout, PostCard, PaginationHelper, PostsRow } from '../components/common'
// import { MetaData } from '../components/common/meta'
import { Row, Col, Divider, Typography, Tag } from 'antd'
const { Title, Text } = Typography
import { Parallax } from 'rc-scroll-anim'
/**
* Contact page
*
*/
const Contact = ({ data, location, pageContext }) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const [ email, setEmail ] = useState(``)

    if (email === ``) {
        setTimeout(()  => {
            setEmail(`joe@joeczubiak.com`)
        }, 3000)
    }


    const page = {
        page: {
            title: `Contact`,
            meta_title: ``,
            meta_description: `Contact me. Fill out the form to send me a message.`,
            name: ``,
            feature_image: ``,
            description: `Contact me. Fill out the form to send me a message.`,
        },
    }

    return (
        <>
            <MetaData location={location} data={page} />
            <MainLayout className={`page`}>
                <Row
                    gutter={[16, 24]}
                    justify={`space-around`}
                >
                    <Col
                        xs={24}
                        sm={24}
                        md={18}
                        lg={16}
                    >
                        <Typography>
                            <Title>
                                Contact
                            </Title>
                        </Typography>

                        {/*<HtmlParser html={page.html} />*/}

                        <Typography>
                            Please fill out the form below if you'd like to get in touch.
                        </Typography>
                        <Typography>
                            Or email me at {email}
                        </Typography>

                        <br />

                        <NetlifyContactForm />

                    </Col>
                </Row>

            </MainLayout>
        </>
    )
}

Contact.propTypes = {
    data: PropTypes.shape({
        site: PropTypes.shape({
            siteMetadata: PropTypes.object,
        }),
    }),
}

export default Contact

export const pageQuery = graphql`
query {
    site {
      siteMetadata {
        title
      }
    }
  }
 `
